// Built-in
import React, { useState } from 'react';

// MUI
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Local
import { deleteSourceAPI } from '../../../../app/api/sources';

// 3rd part
import { useTranslation } from 'react-i18next';

const DeleteButton = ({ session, isActionAllowed, selectedRowData }) => {

    const [openDialog, setOpenDialog] = useState(false);
    const { t } = useTranslation();

    const handleDeleteClick = () => {
        const source = selectedRowData;
        if (source.chats_ids.length > 0) {
            setOpenDialog(true);
        } else {
            deleteSource(source);
        }
    };

    const deleteSource = async (source) => {
        // Close the dialog
        setOpenDialog(false);

        // Call API to delete the source
        await deleteSourceAPI(source.id, session.access_token);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Button
                variant="outlined"
                className="button delete-button"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteClick}
                disabled={!isActionAllowed('delete')}
                sx={{
                    color: 'red',
                    borderColor: 'red',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.1)'
                    },
                    '&:disabled': {
                        color: 'rgba(0, 0, 0, 0.26)',
                        borderColor: 'rgba(0, 0, 0, 0.12)'
                    }
                }}
            >
                {t('sourcesOptionsDeleteButton')}
            </Button>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('sourcesOptionsDeleteButtonDialogHeader')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {t('sourcesOptionsDeleteButtonDialogText')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        {t('sourcesOptionsDeleteButtonDialogCancelButton')}
                    </Button>
                    <Button onClick={() => deleteSource(selectedRowData)} autoFocus>
                        {t('sourcesOptionsDeleteButtonDialogProceedButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DeleteButton;