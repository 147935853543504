import { baseRequest } from "./baseRequest"

const usersEndpoint = "/teams"

export async function addTeamUserAPI(teamId, payload, accessToken) {
    const createUserAPIEndpoint = `/${teamId}/add-user`
    const requestEndpoint = usersEndpoint + createUserAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "POST", accessToken, payload)
    return payloadResponse
}

export async function getTeamUsersAPI(teamId, accessToken) {
    const createUserAPIEndpoint = `/${teamId}/users`
    const requestEndpoint = usersEndpoint + createUserAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "GET", accessToken)
    return payloadResponse
}

export async function removeUserAPI(teamId, userEmail, accessToken) {
    const createUserAPIEndpoint = `/${teamId}/users/${userEmail}`
    const requestEndpoint = usersEndpoint + createUserAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "DELETE", accessToken)
    return payloadResponse
}