// Built-in
import React from 'react';

// MUI
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';

// 3rd part
import { useTranslation } from 'react-i18next';

// Local
import { getSubscriptionNoLoginBillingPageURLAPI } from '../../../../app/api/subscriptions';

const SubscriptionOption = ({ isActive, session }) => {

  const { t } = useTranslation();

  const handleSubscriptionButton = async () => {
    
    const getSubscriptionNoLoginBillingPageURLAPIEndpointResponse = await getSubscriptionNoLoginBillingPageURLAPI(session.access_token)
    const subscriptionNoLoginBillinPageURL = getSubscriptionNoLoginBillingPageURLAPIEndpointResponse.url
    window.open(subscriptionNoLoginBillinPageURL, '_blank'); // Open in a new tab

  }

  return (
    <ListItem 
      button
      onClick={handleSubscriptionButton}
      style={{ backgroundColor: isActive('/settings') ? '#f4f4f4' : 'transparent' }}
    >
      <ListItemIcon>
        <CreditCardIcon style={{ color: 'black' }} />
      </ListItemIcon>
      <ListItemText primary={t('subscriptionButton')} />
    </ListItem>
  );
};

export default SubscriptionOption;
