export async function baseRequest(endpoint, method, accessToken, body = null) {

    const backendEndpoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
    const requestEndpoint = backendEndpoint + endpoint

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
    }

    // Building request options
    const requestOptions = {
        method: method,
        headers: headers
    };

    if (body) {
        requestOptions.body = JSON.stringify(body);
    }
    
    let response = await fetch(requestEndpoint, requestOptions);
    if (response.status === 200) {
        const payload = response.json()
        return payload
    } else if (response.status === 401) {        
        console.log('Error 401: Unauthorized!');
        console.log(requestOptions)
        console.log(requestEndpoint)
        console.log(response.statusText)
        return null        
    } else if (response.status === 404) {
        console.log('Error 404: Endpoint not found!');
        console.log(requestOptions)
        console.log(requestEndpoint)
        console.log(response.statusText)
        return null
    } else if (response.status === 422) {
        console.log('FastAPI Unprocessable Entity 422: Message Body Wrong!');
        console.log(requestOptions)
        console.log(requestEndpoint)
        console.log(response.statusText)
        return null
    } else {
        console.log(requestOptions)
        console.log(requestEndpoint)
        console.log('Error not 401 or 404 but: ', response.status)
        console.log('Error message: ', response.statusText)
        return null
    }
}