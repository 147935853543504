import { baseRequest } from "./baseRequest"

const transcriptionsEndpoint = "/transcriptions"

export async function requestToTranscriptAPI(payload, accessToken) {
    const requestToTranscriptAPIEndpoint = '/transcript'
    const requestEndpoint = transcriptionsEndpoint + requestToTranscriptAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "POST", accessToken, payload)
    return payloadResponse
}

