// Built-in
import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';

// 3rd part
import { useTranslation } from 'react-i18next';

const TranscriptionsOption = ({ isActive }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ListItem 
      button 
      style={{ backgroundColor: isActive('/transcriptions') ? '#f4f4f4' : 'transparent' }}
      onClick={() => {              
        navigate("/transcriptions");
      }}
    >
      <ListItemIcon>
        <SourceIcon style={{ color: 'black' }}/>
      </ListItemIcon>
      <ListItemText primary={t('Transcrições')} />
    </ListItem>
  );
};

export default TranscriptionsOption;
