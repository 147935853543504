// Built-in
import React from 'react';
import { useLocation } from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Drawer, List, Divider } from '@mui/material';

// 3rd part
import { useTranslation } from 'react-i18next';

// Local modules
import './index.css';

// Drawer Options
import HomeOption from './options/home';
import UsersOption from './options/users';
import TranscriptionsOption from './options/transcriptions';
import UserAuthStatus from './options/userAuthStatus';
import SubscriptionOption from './options/subscription';
import PlansOption from './options/plans';
import { Home } from '@mui/icons-material';

// Import the logo
import maia_logo from './maia_logo.png';

const PermanentDrawer = ({ userSession, isSubscribed }) => {

  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const { t } = useTranslation();

  // Helper function to check if the trial period has expired
  const isTrialExpired = () => {
    if (userSession?.user?.payload?.status === 'trialing' && userSession.user.payload.trial_expires_at) {
        const now = new Date();
        const trialExpiresAt = new Date(userSession.user.payload.trial_expires_at);
        return now >= trialExpiresAt; // Returns true if the current time is past the trial expiration time
    }
    return null; // Return null if there's no trial expiration info or user is not trialing
  };


  // Check if the trial has expired
  const trialNotExpiredOrUnknown = isTrialExpired() !== true; // True if null or trial not expired

  const drawerWidth = 220;

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer 
        variant="permanent" 
        anchor="left" 
        className="drawer-container"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { 
            width: drawerWidth, 
            boxSizing: 'border-box' 
          },
        }}
      >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ overflow: 'auto', flex: '1'}}>
          {/* Add the logo here */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px' }}>
            <img src={maia_logo} alt="Company Logo" style={{ maxWidth: '100%', maxHeight: '80px' }} />
          </Box>
        <List>
          <HomeOption
            isActive={isActive}
          />
          {/* For every user*/}
          {trialNotExpiredOrUnknown && (
            <TranscriptionsOption 
              isActive={isActive}
            />
          )}
          {/* If the user is a team admin*/}
          {userSession.user.payload.role === "team_admin" && (
            <UsersOption
              session={userSession}
              isActive={isActive}
              to={'/users'}
            />
          )}
          {/* If the user has no active plan*/}          
          {!isSubscribed && (<PlansOption/>)}
          {/* If the user has active plan*/}          
          {((userSession.user.payload.role === "team_admin" || userSession.user.payload.role === "admin") && isSubscribed) && (
            <SubscriptionOption
              isActive={isActive}
              session={userSession}
            />
          )}
        </List>
        </Box>
          <List>
            <Divider/>
            <UserAuthStatus 
              userSession={userSession}
            />          
          </List>
        </Box>
    </Drawer>
    </Box>
  );
};

export default PermanentDrawer;
