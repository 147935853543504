// Built-in
import React, { useState } from 'react';

// 3rd part
import { useTranslation } from 'react-i18next';

// Local
import './page.css';
import { getSubscriptionStarterURLAPI, getSubscriptionTeamsCheckoutLinkAPI } from '../api/subscriptions';
import PricingTable from '../../components/pricingTable';
import LoadingWidget from '../../components/utils/loadingWidget';

const PlansPage = (userSession) => {
    const session = userSession.session;
    const [isLoading, setIsLoading] = useState(false);
    const [userCount, setUserCount] = useState(2);

    const { t } = useTranslation();

    const handleSelectedPlan = async (planId) => {
        let planLinkUrl;

        setIsLoading(true)
        if (planId === "personal") {
            const getSubscriptionStarterURLAPIResponse = await getSubscriptionStarterURLAPI(session.access_token);
            planLinkUrl = getSubscriptionStarterURLAPIResponse.checkout_url;
        } else if (planId === "teams") {
            const getSubscriptionTeamsCheckoutLinkResponse = await getSubscriptionTeamsCheckoutLinkAPI(userCount, session.access_token)
            planLinkUrl = getSubscriptionTeamsCheckoutLinkResponse.checkout_url
        } else if (planId === "enterprise") {
            planLinkUrl = "https://9-v.ai/demo"
        }
        setIsLoading(false)

        window.location.href = planLinkUrl
    };
    
    const plansData = [
        {
            id: "personal",
            title: t('plansPage.solo.title'),
            price: t('plansPage.solo.price'),
            features: t('plansPage.solo.features', { returnObjects: true }),
            ctaTitle: t('plansPage.solo.ctaTitle')
        },
        {
            id: "teams",
            title: t('plansPage.teams.title'),
            billingType: t('plansPage.teams.billingType'),
            price: t('plansPage.teams.price'),
            features: t('plansPage.teams.features', { returnObjects: true }),
            ctaTitle: t('plansPage.teams.ctaTitle')
        },
        {
            id: "enterprise",
            title: "Enterprise",
            price: "Custom",
            features: t('plansPage.teams.features', { returnObjects: true }),
            ctaTitle: t('plansPage.teams.ctaTitle')
        }
    ];
    
    return (
        <>
        {isLoading ? (
            <LoadingWidget />
          ) : (     
        <div className="pricing-table-container">
          <PricingTable 
            plans={plansData} 
            onPlanSelect={handleSelectedPlan} 
            userCount={userCount}
            setUserCount={setUserCount}/>
        </div>)}
        </>
    );
};

export default PlansPage;
