import { baseRequest } from "./baseRequest"

const usersEndpoint = "/users"

export async function createUserAPI(payload, accessToken) {
    const createUserAPIEndpoint = `/create`
    const requestEndpoint = usersEndpoint + createUserAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "POST", accessToken, payload)
    return payloadResponse
}

export async function approveUserConsent(accessToken) {
    const approveUserConsentEndpoint = `/approve-consent`
    const requestEndpoint = usersEndpoint + approveUserConsentEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "POST", accessToken)
    return payloadResponse
}

export async function deleteUserAPI(email, accessToken) {
    const approveUserConsentEndpoint = `/${email}`
    const requestEndpoint = usersEndpoint + approveUserConsentEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "DELETE", accessToken)
    return payloadResponse
}