// Built-in
import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';

const UserAuthStatus = ({ userSession }) => {
  
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/logout")
  };

  return (
    <ListItem style={{ width: '100%', justifyContent: 'space-between' }}>
        <>
          <ListItemIcon>
            <Avatar src={userSession.user.user_metadata.avatar_url} />
          </ListItemIcon>
          <ListItemText 
            primary={userSession.user.payload.full_name || 'No Name'} // Assuming full_name is the key for the user's name
            secondary={userSession.user.payload.email}
            primaryTypographyProps={{
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '15px'
              }
            }}
            secondaryTypographyProps={{
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '10px'
              }
            }}
          />
          <Tooltip title="Logout">
            <Button
              onClick={handleLogout}
              variant="text"
              size="small" // Make the button smaller
              style={{
                minWidth: 'auto', // Reduce the minimum width
                padding: '6px', // Adjust padding as needed
                marginLeft: 'auto',
                color: 'black'
              }}
            >
              <LogoutIcon fontSize="small" />
            </Button>
          </Tooltip>
        </>
    </ListItem>
  );
};

export default UserAuthStatus;
