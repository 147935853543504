// Built-in
import React from 'react';

// MUI
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';

// 3rd part
import { useTranslation } from 'react-i18next';

const PricingPlan = ({ plan, onPlanSelect, userCount, setUserCount }) => {

  const { t } = useTranslation();

  const handleAddUser = () => {
    setUserCount(userCount + 1);
  };

  const handleRemoveUser = () => {
    if (userCount > 2) {
      setUserCount(userCount - 1);
    }
  };

  return (
    <div className="pricing-plan">
      <h2 className="plan-title">{plan.title}</h2>
      <div className="plan-price">
        <span className="price" style={{ fontSize: '25px' }} >{plan.price}</span>
      </div>
      <div>
        {plan.billingType}
      </div>
      {plan.id === "teams" && (
        <div>
        <Box display="flex" alignItems="center" justifyContent="center"> 
          <IconButton
            aria-label="Remove User"
            onClick={handleRemoveUser}
            disabled={userCount === 2}
            style={{ margin: '0 5px 0 0' }}
          >
            <RemoveIcon />
          </IconButton>
          <Typography variant="body2">{userCount}</Typography>
          <IconButton
            aria-label="Add User"
            onClick={handleAddUser}
            disabled={userCount >= 30}
            style={{ margin: '0 0 0 5px' }}
          >
            <AddIcon />
          </IconButton>
        </Box>
        </div>
      )}
      <br/>
      <ul className="plan-features">
        {plan.features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
            <button 
        className="plan-action" 
        onClick={() => onPlanSelect(plan.id)}
      >
        {plan.ctaTitle}            
      </button>
    </div>
  );
};

const PricingTable = ({ plans, onPlanSelect, userCount, setUserCount }) => {

  return (
    <div className="pricing-table-container">
      {plans.map((plan, index) => (
        <PricingPlan 
            key={index} 
            plan={plan} 
            onPlanSelect={onPlanSelect} 
            userCount={userCount}
            setUserCount={setUserCount}/>
      ))}
    </div>
  );
};

export default PricingTable;
