import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Chip,
  Box,
  Alert
} from '@mui/material';

const EmailDialog = ({ open, handleClose, handleSubmit, t, addedEmails, setAddedEmails }) => {
  const [newUser, setNewUser] = useState('');
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

  const handleUserChange = (event) => {
    setNewUser(event.target.value);
  };

  const handleAddEmail = (event) => {
    if (event.key === 'Enter' && newUser) {
      setAddedEmails([...addedEmails, newUser]);
      setNewUser('');
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setAddedEmails(addedEmails.filter(email => email !== emailToRemove));
  };

  const handleFormSubmit = () => {
    // Handle form submission logic here
    handleSubmit(addedEmails);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>{t('usersPage.addNewUserDialogTitle')}</DialogTitle>
      {alert.open && (
        <Alert severity={alert.severity} style={{ margin: '0 24px', color: 'black' }}>
          {alert.message}
        </Alert>
      )}
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label={t('usersPage.emailLabel')}
          type="email"
          fullWidth
          value={newUser}
          onChange={handleUserChange}
          onKeyPress={handleAddEmail}
          multiline
          placeholder={t('usersPage.addNewUserDialogPlaceholder')}
          variant="outlined"
          rows={5}
          InputProps={{
            style: { borderColor: 'black' },
            classes: {
              notchedOutline: 'black-border',
              focused: 'black-border-focused'
            }
          }}
          InputLabelProps={{
            style: { color: 'black' },
          }}
        />
        <Box mt={2}>
          {addedEmails.map((email, index) => (
            <Chip
              key={index}
              label={email}
              onDelete={() => handleRemoveEmail(email)}
              color="primary"
              style={{ marginRight: '5px', marginBottom: '5px' }}
            />
          ))}
        </Box>
        <Typography variant="body2" style={{ marginTop: '8px' }}>
          {t('usersPage.addNewUserDialogHint')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: 'black' }}>
          {t('usersPage.cancelButton')}
        </Button>
        <Button onClick={handleFormSubmit} style={{ color: 'black' }}>
          {t('usersPage.addButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmailDialog;
