// Built-in
import React, { useState, useRef, useEffect } from 'react';

// 3rd part
import axios from 'axios';

// MUI
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { LinearProgress } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Local
import { createSourceAPI } from '../../../../app/api/sources';
import { requestToTranscriptAPI } from '../../../../app/api/transcriptions';
import { supabase } from '../../../../hooks/supabase';
import formatBytes from '../../../utils/formatBytes';

// 3rd part
import { useTranslation } from 'react-i18next';

const UploadButton = ({ session }) => {

    // States
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFileType, setSelectedFileType] = useState('');
    const [factLimits, setFactLimits] = useState({'allowed_services': []});
    const [anchorEl, setAnchorEl] = useState(null);
    
    // Other
    const inputFileRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {

        const fetchData = async () => {

            const userId = session.user.id;
            
            // Retrieve plan limits
            try {            
                let customsReferenceTable;
                let customsReferenceFilterField;
                let customsReferenceFilterValue;

                if (session.user.payload.team_id) {
                    customsReferenceTable = 'teams_limits'
                    customsReferenceFilterField = 'team_id'
                    customsReferenceFilterValue = session.user.payload.team_id
                } else {
                    customsReferenceTable = 'users_limits'
                    customsReferenceFilterField = 'user_id'
                    customsReferenceFilterValue = userId
                }
  
                const { data: customLimitsData, error: customLimitsError } = await supabase
                .from(customsReferenceTable)
                .select('max_sources, max_storage_gb, max_text_input_mb, max_audio_input_mb, max_audio_seconds_transcripted_monthly, allowed_services')
                .eq(customsReferenceFilterField, customsReferenceFilterValue);
                
                if (customLimitsError) {
                    throw customLimitsError;
                }

                const customLimits = customLimitsData[0]
                setFactLimits(customLimits);

            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
            };
      
        fetchData();
      }, [session]);
      

    const handleUploadButtonClick = (event) => {
        // Validate if user can upload more sources
        const currentSourcesAdded = session.user.payload.sources_added
        const maxSources = factLimits.max_sources
        if (currentSourcesAdded > maxSources) {
            alert(t('sourcesMaxSourcesExceedWarn') + `\n\n${t('sourcesMaxSourcesExceedWarnCurrentNumber')}${currentSourcesAdded}\n${t('sourcesMaxSourcesExceedWarnMaxNumber')}${maxSources}`)
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    
    const handleUploadButtonClose = () => {
        setAnchorEl(null);
    };

    const handleFileSelection = (fileType) => {
        handleUploadButtonClose();
        setSelectedFileType(fileType);
        inputFileRef.current.accept = fileType; // Set the accepted file type
        inputFileRef.current.click(); // Open file input        
    };

    const hasAnyFileInvalidSize = async (files) => {

        let maxInputMBSize;
        let anyInvalid = false;

        for (const file of files) {
            if (file.type === 'audio/mpeg') {
                maxInputMBSize = factLimits.max_audio_input_mb
            } else if (file.type === 'application/pdf') {
                maxInputMBSize = factLimits.max_text_input_mb
            }

            const maxInputBytesSize = maxInputMBSize * 1024 * 1024
            if (file.size > maxInputBytesSize) {
                anyInvalid = true;
            }
        }

        return anyInvalid
    };

    const willExceedStorageLimit = async (files) => {

        let bytesToBeAdded = 0;
        let exceed = false;

        for (const file of files) {
            bytesToBeAdded += file.size
        }

        const currentBytesStoraged = session.user.payload.bytes_storaged
        const totalBytes = bytesToBeAdded + currentBytesStoraged

        const maxGBOfStorage = factLimits.max_storage_gb
        const maxBytesOfStorage = maxGBOfStorage * 1024 * 1024 * 1024;

        if (totalBytes > maxBytesOfStorage) {
          exceed = true;
        }

        return exceed;
    }

    const getTotalAudioSeconds = async (files) => {
        let secondsToBeAdded = 0;
      
        // Create an array of Promises to track loading for each audio file
        const loadingPromises = [];
      
        for (const file of files) {
            const audioElement = new Audio();
            audioElement.src = URL.createObjectURL(file);
    
            // Create a Promise that resolves when audio metadata is loaded
            const metadataLoaded = new Promise((resolve) => {
            audioElement.onloadedmetadata = () => {
                resolve(Math.round(audioElement.duration));
            };
            });
    
            loadingPromises.push(metadataLoaded);
        }
      
        // Wait for all promises to resolve and sum the durations
        const durations = await Promise.all(loadingPromises);
        secondsToBeAdded = durations.reduce((total, duration) => total + duration, 0);
      
        return secondsToBeAdded;
      };      

    const fetchMonthlyAudioSecondsTranscripted = async (userId) => {

        const currentMonthYear = new Date().toISOString().slice(0, 7) + "-01";

        try {
            const { data, error } = await supabase
            .from('monthly_user_usage')
            .select('audio_seconds_transcripted')
            .eq('user_id', session.user.id)
            .eq('month_year', currentMonthYear)
            .single();

            if (error) {
                throw error;
            }

            return data.audio_seconds_transcripted

        } catch (error) {
            console.log("Error trying to fetch monthly audio seconds transcripted: ", error)
        }
    }

    const willExceedMonthlyAudioSecondsLimit = async (files) => {
        let exceed = false;
        const secondsToBeAdded = await getTotalAudioSeconds(files)
        console.log("Got Here 1")
        console.log("secondsToBeAdded: ", secondsToBeAdded)
        if (secondsToBeAdded > 0) {
            console.log("Got Here 2")
            const audioSecondsTranscripted = await fetchMonthlyAudioSecondsTranscripted(session.user.id)
            console.log("audioSecondsTranscripted: ", audioSecondsTranscripted)
            const totalSeconds = secondsToBeAdded + audioSecondsTranscripted
            console.log("factLimits.max_audio_seconds_transcripted_monthly: ", factLimits.max_audio_seconds_transcripted_monthly)
            const maxAudioSeconds = factLimits.max_audio_seconds_transcripted_monthly
            console.log("totalSeconds: ", totalSeconds)
            if (totalSeconds > maxAudioSeconds) {
                exceed = true
            }
        }         
        return exceed;
    }

    const performValidationsPreUpload = async (files) => {

        // Validate sources sizes
        const anyInvalidSize = await hasAnyFileInvalidSize(files)

        if (anyInvalidSize) {
            alert(t('sourcesMaxSourcesSizeExceedWarn') + `${factLimits.max_audio_input_mb}MB`)
            return true;
        }

        // Validate storage limit
        const exceedStorageLimit = await willExceedStorageLimit(files)

        if (exceedStorageLimit) {
            const currentBytesStoraged = session.user.payload.bytes_storaged
            const currentGBStoragedFormatted = formatBytes(currentBytesStoraged)
            alert(t('sourcesMaxSourcesStorageExceedWarn') + `\n\n${t('sourcesMaxSourcesStorageExceedInUseWarn')}${currentGBStoragedFormatted}\n${t('sourcesMaxSourcesStorageExceedMaximumWarn')}${factLimits.max_storage_gb} GB`)
            return true;
        }
    
        // Validate monthly audio seconds transcripted
        // const exceedMonthlyAudioSecondsLimit = await willExceedMonthlyAudioSecondsLimit(files)

        // if (exceedMonthlyAudioSecondsLimit) {
        //     alert(t('sourcesMaxSourcesAudioSecondsExceedWarn'))
        //     return true;
        // }

        return false
    } 

    const handleUploadFileClick = async (event) => {
        
        const files = event.target.files;

        const hasAnyInvalidation = await performValidationsPreUpload(files)
        if (hasAnyInvalidation) {
            return
        }
        
        // Prepare bulk file metadata
        const filesMetadata = Array.from(files).map(file => ({
            name: file.name,
            size: file.size,
            type: selectedFileType,
            mime_type: file.type,
            category: 'file'
        }));

        try {
            
            // Creates Sources
            const createSourceAPIResponse = await createSourceAPI(filesMetadata, session.access_token)
            const createdSources = createSourceAPIResponse.sources;

            // Upload each file to S3 using the pre-signed URL
            for (let i = 0; i < createdSources.length; i++) {
                const source = files[i];
                const preSignedUrl = createdSources[i].pre_signed_url;

                try {
                    await axios.put(preSignedUrl, source, {
                        headers: {
                            "Content-Type": source.type
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        }
                    });
    
                } catch (error) {
                    console.log(`Source ${source.name} not uploaded. Error: ${error}`);
                }
            }

            // Call to embed files
            // requestToEmbedAPI(createdSources, session.access_token)
            requestToTranscriptAPI(createdSources, session.access_token)

        } catch (error) {
            console.error("Error uploading file:", error);
            // Handle the error scenario
        }

        inputFileRef.value = null
    };    
      
    return (
        <div>
            <Button
                variant="contained"
                component="span"
                startIcon={uploadProgress > 0 && uploadProgress < 100 ? <LinearProgress /> : <AddIcon />}
                onClick={() => inputFileRef.current.click()}
                endIcon={<ArrowDropDownIcon />}
            >
                {uploadProgress > 0 && uploadProgress < 100 ? `${t('sourcesOptionsNewSourceButtonLoading')} ${uploadProgress}%` : t('sourcesOptionsNewSourceButton')}
            </Button>
            <input
                type="file"
                multiple
                accept={factLimits.allowed_services.map(type => `.${type}`).join(',')}
                ref={inputFileRef}
                onChange={handleUploadFileClick}
                style={{ display: 'none' }}
                id="file-upload"
            />
        </div>
    );    
}

export default UploadButton;