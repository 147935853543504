// React
import React, { useState, useCallback, useEffect } from 'react';

// 3rd part
import { useTranslation } from 'react-i18next';

// Aggrid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// Local
import './index.css';
import formatDate from '../../utils/formatDate';
import formatBytes from '../../utils/formatBytes';

const SourcesTable = ({ rowData, onRowSelected }) => {
    const { t } = useTranslation();
    const [columnDefs, setColumnDefs] = useState([]);

    useEffect(() => {
        setColumnDefs([
            {   
                headerName: '', 
                field: '', 
                flex: 0.1,
                checkboxSelection: true,
                headerCheckboxSelection: false,
            },
            { 
                headerName: t('sourcesTableNameHeader'), 
                field: 'name', 
                flex: 1
            },
            { 
                headerName: t('sourcesTableSizeHeader'), 
                field: 'size', 
                flex: 0.5,
                valueFormatter: (params) => formatBytes(params.value),
            },
            { 
                headerName: t('sourcesTableAddedOnHeader'), 
                field: 'created_at', 
                flex: 0.5, 
                sort: 'desc',
                // sortIndex: 0, // Optional if sorting by multiple columns
                valueFormatter: (params) => formatDate(params.value),
            },
            { 
                headerName: t('sourcesTableStatusHeader'), 
                field: 'status', 
                flex: 0.3,
                valueFormatter: (params) => t(`sourcesStatus${params.value}`)
            },
        ]);
    }, [t]); // Dependency array includes t to update columns when language changes

    const defaultColDef = {
        sortable: true,
        editable: false,
        flex: 1,
        filter: false,
        floatingFilter: false
    };

    const getRowId = useCallback(params => params.data.id, []);

    // Define constants for status keys
    const STATUS_KEYS = {
        READY: "READY",
        ERROR: "ERROR",
        //... other statuses
    };

    // Function to determine if row is selectable
    const isRowSelectable = useCallback(node => {
        return [STATUS_KEYS.READY, STATUS_KEYS.ERROR].includes(node.data.status);
    }, []);
    

    return (
        <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
            <AgGridReact
                getRowId={getRowId}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection={'single'}
                suppressRowClickSelection={true}
                onRowSelected={onRowSelected}
                overlayNoRowsTemplate="<div style='padding: 10px; text-align: center;'>Clique em '+ Fonte' para adicionar novas fontes.</div>"
                isRowSelectable={isRowSelectable}
                pagination={true}
                paginationPageSize={12}
                domLayout='autoHeight'
            />
        </div>
    );
};

export default SourcesTable;