// Built-in
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';

// Other
import { useTranslation } from 'react-i18next';

// Local
import './page.css';
import { supabase } from '../../../hooks/supabase';
import companyLogo from '../login/9v_ai_logo_no_bg.png';

const SignUpPage = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { t } = useTranslation();

    const navigate = useNavigate();

    const checkIfAllFieldsAreFilled = () => {
        if (!firstName || !lastName || !email || !password || !confirmPassword) {
            return t("signUpPage.allFieldsRequired");
        }
        return null;
    }

    const checkIfEmailIsValid = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return t("signUpPage.invalidEmail");
        }
        return null;
    }

    const checkIfPasswordsAreValid = () => {
        if (password.length < 8) {
            return t("signUpPage.passwordTooShort");
        }
        if (password !== confirmPassword) {
            return t("signUpPage.wrongConfirmationPasswordTitle");
        }
        return null;
    }

    const areAllFieldsValid = () => {
        const validators = [checkIfAllFieldsAreFilled, checkIfEmailIsValid, checkIfPasswordsAreValid];
        for (const validator of validators) {
            const error = validator();
            if (error) {
                alert(error);
                return false;
            }
        }
        return true;
    }

    const handleSignInWithGoogle = async () => {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google'
        });
        if (error) {
            console.error('Error signing in:', error);
        } else {
            navigate('/home')
        }
    };

    const handleSignUp = async () => {

        const allFieldsValid = areAllFieldsValid()
        if (!allFieldsValid) return;

        const { data, error } = await supabase.auth.signUp(
            { 
                email: email,
                password: password, 
                options: {
                    data: {
                        first_name: firstName,
                        last_name: lastName
                    }
                }
            }
        );

        if (error) {
            console.error('Error during sign up:', error);
            alert(error.message);            
        } else {

            // If the sign up is succesfull, reuse credentials for login
            const redirectTo = `${window.location.origin}/home`;
            const { data, error } = await supabase.auth.signInWithPassword(
                { 
                    email: email,
                    password: password,
                    options: { redirectTo }
                }
            );

            if (error) {
                console.error('Error during log in:', error);
                alert(error.message);            
            } else {
                navigate('/home', { replace: true });
            }
        }
    };

    return (
        <div className="page-container">
            <div className="auth-container">
                <img src={companyLogo} alt="Company Logo" className="company-logo" />
  
                <div className="login-input-container">
                    <Button 
                        variant="contained"
                        style={{ backgroundColor: 'white', color: 'black' }}
                        onClick={handleSignInWithGoogle}
                        className="login-btn google-login"
                        endIcon={<GoogleIcon/>}
                    >
                        {t('signUpPage.loginWithGoogleTitle')}
                    </Button>
                    <br/>
                    <br/>
                    <div>{t('loginPage.loginOrOption')}</div>
                    <br/>                    
                    <input 
                        type="text"
                        placeholder={t('signUpPage.firstNameTitle')}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input 
                        type="text"
                        placeholder={t('signUpPage.lastNameTitle')}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <input 
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input 
                        type="password"
                        placeholder={t('signUpPage.passwordTitle')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input 
                        type="password"
                        placeholder={t('signUpPage.confirmPasswordTitle')}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>

                <div className="signup-button-container">
                    <Button 
                        variant="contained"
                        style={{ backgroundColor: 'black', color: 'white' }}
                        onClick={handleSignUp}
                        className="signup-btn"
                    >
                        {t('signUpPage.signUpButton')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;
