import { baseRequest } from "./baseRequest"

const sourcesEndpoint = "/sources"

export async function createSourceAPI(payload, accessToken) {
    const createSourceEndpoint = '/create'
    const requestEndpoint = sourcesEndpoint + createSourceEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "POST", accessToken, payload)
    return payloadResponse
}

export async function deleteSourceAPI(sourceId, accessToken) {
    const deleteSourceAPIEndpoint = `/${sourceId}/delete`
    const requestEndpoint = sourcesEndpoint + deleteSourceAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "DELETE", accessToken)
    return payloadResponse
}

export async function getAWSDownloadLinkAPI(sourceId, mode, accessToken) {
        const getAWSDownloadLinkAPIEndpoint = `/${sourceId}/download/${mode}`
        const requestEndpoint = sourcesEndpoint + getAWSDownloadLinkAPIEndpoint    
        const payloadResponse = await baseRequest(requestEndpoint, "GET", accessToken)
        return payloadResponse
}

export async function requestToEmbedAPI(payload, accessToken) {
        const requestToEmbedAPIEndpoint = '/embed'
        const requestEndpoint = sourcesEndpoint + requestToEmbedAPIEndpoint
        const payloadResponse = await baseRequest(requestEndpoint, "POST", accessToken, payload)
        return payloadResponse
}