// Built-in
import React from 'react';

// MUI Imports
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Local Imports
import { getAWSDownloadLinkAPI } from '../../../../app/api/sources';

// 3rd Party Imports
import { useTranslation } from 'react-i18next';

const DownloadButton = ({ session, isActionAllowed, selectedRowData }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadClick = async (mode) => {
        handleClose();  // Close the menu first
        const sourceId = selectedRowData.id;
        const AWSDownloadURLResponse = await getAWSDownloadLinkAPI(sourceId, mode, session.access_token);
        const AWSDownloadURL = AWSDownloadURLResponse.aws_download_url;
        const downloadButton = document.createElement('a');
        downloadButton.href = AWSDownloadURL;
        downloadButton.download = selectedRowData.name
        downloadButton.click();
    };

    return (
        <> 
        <Button
            variant="contained"
            className="button download-button"
            startIcon={<FileDownloadIcon />}
            endIcon={<ArrowDropDownIcon />}
            onClick={handleClick}
            disabled={!isActionAllowed('download')}
            sx={{
                color: 'white',
                backgroundColor: 'black',
                borderColor: 'black',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.9)'
                },
                '&:disabled': {
                    color: 'rgba(255, 255, 255, 0.5)',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    borderColor: 'rgba(0, 0, 0, 0.3)'
                }
            }}
        >
            {t('sourcesOptionsDownloadButton')}
        </Button>
        <Menu
            id="download-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
        <MenuItem onClick={() => handleDownloadClick(0)}>{t('Texto corrido')}</MenuItem>
        {selectedRowData.s3_obj_key_paragraphed_text && (<MenuItem onClick={() => handleDownloadClick(1)}>{t('Texto com parágrafos')}</MenuItem>)}
        {selectedRowData.s3_obj_key_diarized_text && (<MenuItem onClick={() => handleDownloadClick(2)}>{t('Texto com interlocutores')}</MenuItem>)}
        </Menu>
        </>
    );
};

export default DownloadButton;
