// Custom function to format date as "DD-MM-YYYY"
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (adding 1 as months are zero-based) and pad with leading zero
    const year = date.getFullYear().toString(); // Get full year
  
    return `${day}-${month}-${year}`;
  };

export default formatDate;