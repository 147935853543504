// React
import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// 3rd part
import { Provider } from 'jotai'

// Local API
import { createUserAPI } from './app/api/users';
import { getSubscriptionStatusAPI } from './app/api/subscriptions';

// MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Local Pages
import HomePage from './app/home/page';
import UsersPage from './app/users/page';
import PlansPage from './app/plans/page';
import TranscriptionsPage from './app/transcriptions/page';
import LoginPage from './app/auth/login/page';
import LogoutPage from './app/auth/logout';
import SignUpPage from './app/auth/signup/page';

// Local Components
import PermanentDrawer from './components/permanentDrawer';
import BottomBar from './components/permanentDrawer/options/bottomWarningBar';

// Local DB
import { supabase } from './hooks/supabase';

import i18n from './config/i18n';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
  },
});

const LayoutWithDrawer = ({ children, userSession, isSubscribed }) => {

  return (
    <div>
      <PermanentDrawer userSession={userSession} isSubscribed={isSubscribed} />
      {!isSubscribed && <BottomBar userSession={userSession} />}
      <div className="ag-grid-container">
        {children}
      </div>
    </div>
  );
};

const App = () => {

  const [userSession, setUserSession] = useState(undefined);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const isCreatingUserRef = useRef(false);
  
  useEffect(() => {

    const deploymentMode = process.env.REACT_APP_DEPLOYMENT_MODE;

    const checkSubscriptionStatus = async (session) => {
      // If there is no session or no user attached to a session, skip
      if (!session || !session.user) {
        return false;
      }

      // If on development or staging environtment, skip
      // if (deploymentMode === "dev" || deploymentMode === "STG") {
      //   return true
      // }

      // Validate if user is team user
      if (session.user.payload.role === "team_user") {
        return true;
      }

      // If user has stripe_subscription_id, validate with Stripe API
      if (session.user.stripe_subscription_id) {
        try {
          const subscriptionStatusResponse = await getSubscriptionStatusAPI(session.access_token)    
          const subscriptionStatus = subscriptionStatusResponse.subscription_status;
          return subscriptionStatus === 'active' || subscriptionStatus === 'trialing';
        } catch (error) {
            console.error("Error fetching subscription status:", error);
          return false;
        }
      // Else, validate in status field
      } else if (session.user.payload.status === 'active'){
        return true
      } else {
        return false;
      }

    };

    const handleAuthStateChange = async (session) => {
      if (!session || !session.user) {
        return false;
      }

      try {
        const userData = await checkUserExistence(session);
        if (!userData) {
          throw new Error('User data retrieval failed');
        }
  
        session.user.stripe_customer_id = userData.stripe_customer_id;
        session.user.stripe_subscription_id = userData.stripe_subscription_id;
        session.user.payload = userData
        setUserSession(session);        
  
        const isUserSubscribed = await checkSubscriptionStatus(session);
        // console.log("UserSubscriptions: ", isUserSubscribed)
        setIsSubscribed(isUserSubscribed);
      } catch (error) {
        console.error('Error during session update:', error);
      }
    };
  
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {      
      if (session) {
        handleAuthStateChange(session);
      } else {
        setUserSession(null);
        setIsSubscribed(false);        
      }
    });
  
    return () => subscription.unsubscribe();
  }, []);

  const createUserAPIPayload = async (session) => {
    if (!session?.user?.identities?.length) {
      return null;
    }

    const { id, email, user_metadata, identities } = session.user;
    const [identity] = identities;
    const { provider, identity_data, created_at } = identity;

    let full_name, picture_url;

    if (provider === "email") {
      const { first_name = '', last_name = '' } = user_metadata;
      full_name = `${first_name} ${last_name}`.trim();
      picture_url = null;
    } else if (provider === "google") {
      full_name = identity_data?.name;
      picture_url = identity_data?.picture;
    }

    return {
        id,
        email,
        full_name,
        picture_url,
        created_at,
    };
};

  
  const checkUserExistence = async (session) => {
    if (!session || !session.user) {
      return false;
    }

    try {
      const { data: userData, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', session.user.id);
  
      if (error) {
        console.error('Error checking user existence:', error.message);
        return null; // or handle the error as needed
      }
      
      if (userData && userData.length === 0 && isCreatingUserRef.current === false) {
        // User does not exist, create new user
        console.log("Creating new user")
        isCreatingUserRef.current = true;
        try {
          const userPayload = await createUserAPIPayload(session)
          const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
          const createdUserData = await createUserAPI(userPayload, supabaseKey);
          isCreatingUserRef.current = false;
          return createdUserData;
        } catch (createUserAPIError) {
          console.error('User creation error:', createUserAPIError);
          isCreatingUserRef.current = false;
          return null; // or handle the error as needed
        }
      } else {
        // console.log("User already exists or in creation");
        return userData[0]; // Assuming userData contains an array of users
      }
    } catch (error) {
      console.error('Supabase query error:', error.message);
      return null; // or handle the error as needed
    }
  };

  return (
    <div>
      <Router>
        <ThemeProvider theme={theme}>
          <Provider>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/" element={
                userSession === undefined 
                ? <div>Loading...</div>     
                  : userSession === null
                  ? <Navigate to="/login" replace />
                  : <Navigate to="/transcriptions" replace />
              } />
              <Route path="/*"
                element={
                  userSession === undefined 
                  ? <div>Loading...</div>     
                    : userSession === null
                    ? <Navigate to="/login" replace />
                    : (
                      <LayoutWithDrawer userSession={userSession} isSubscribed={isSubscribed}>
                        <Routes>
                          <Route path="/home" element={<HomePage session={userSession} />} />
                          <Route path="/transcriptions" element={<TranscriptionsPage session={userSession} />} />
                          <Route path="/plans" element={<PlansPage session={userSession} />} />
                          <Route path="/users" element={<UsersPage session={userSession}/>} />
                          <Route path="/logout" element={<LogoutPage session={userSession} />} />
                        </Routes>
                      </LayoutWithDrawer>
                    )
                } 
              />              
            </Routes>              
          </Provider>          
        </ThemeProvider>
      </Router>
    </div>
  );  
};

export default App;
