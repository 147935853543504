// Built-In
import React from 'react';
import { Link } from 'react-router-dom';

// MUI
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';

// 3rd part
import { useTranslation } from 'react-i18next';

const UsersOption = ({ isActive }) => {

  const { t } = useTranslation();

  return (
    <ListItem 
      button
      component={Link}
      to={'/users'}
      style={{ backgroundColor: isActive('/users') ? '#f4f4f4' : 'transparent' }}
    >
      <ListItemIcon>
        <GroupIcon style={{ color: 'black' }} />
      </ListItemIcon>
      <ListItemText primary={t('usersButton')} />
    </ListItem>
  );
};

export default UsersOption;
