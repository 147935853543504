import { useEffect } from 'react';
import { supabase } from '../../../hooks/supabase';

const LogoutPage = ({ session }) => {
  useEffect(() => {
    // Perform the logout action asynchronously
    const logout = async () => {
        const { error } = await supabase.auth.signOut()
        if (error) {
            console.log("Error trying to sign out: ", error)
        }
        // Redirect to the desired URL after logout
        window.location.href = 'https://9-v.ai';

    };

    // Call the logout function when the component mounts
    logout();
  }, [session]);

  // Return null or an empty component, as you don't need to render anything in this component
  return null;
};

export default LogoutPage;
