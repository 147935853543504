// Built-in
import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// 3rd part
import { useTranslation } from 'react-i18next';

const PlansOption = ({ }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ListItem 
      button 
      onClick={() => {              
        navigate("/plans");
      }}
    >
      <ListItemIcon>
        <ReceiptLongIcon style={{ color: 'black' }}/>
      </ListItemIcon>
      <ListItemText primary={t('Planos')} />
    </ListItem>
  );
};

export default PlansOption;
