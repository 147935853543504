import { baseRequest } from "./baseRequest"

const subscriptionsEndpoint = "/subscriptions"

export async function getSubscriptionStatusAPI(accessToken) {
    const getSubscriptionStatusAPIEndpoint = '/status'
    const requestEndpoint = subscriptionsEndpoint + getSubscriptionStatusAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "GET", accessToken)
    return payloadResponse
}

export async function getSubscriptionStarterURLAPI(accessToken) {
    const getSubscriptionStarterURLAPIEndpoint = '/checkout-starter'
    const requestEndpoint = subscriptionsEndpoint + getSubscriptionStarterURLAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "GET", accessToken)
    return payloadResponse
}

export async function getSubscriptionTeamsCheckoutLinkAPI(payload, accessToken) {
    const getSubscriptionStarterURLAPIEndpoint = '/checkout-teams'
    const requestEndpoint = subscriptionsEndpoint + getSubscriptionStarterURLAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "POST", accessToken, payload)
    return payloadResponse
}

export async function getSubscriptionNoLoginBillingPageURLAPI(accessToken) {
    const getSubscriptionNoLoginBillingPageURLAPIEndpoint = '/billing-page'
    const requestEndpoint = subscriptionsEndpoint + getSubscriptionNoLoginBillingPageURLAPIEndpoint
    const payloadResponse = await baseRequest(requestEndpoint, "GET", accessToken)
    return payloadResponse
}