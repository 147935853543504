// Built-in
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import GoogleIcon from '@mui/icons-material/Google';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

// Other
import { useTranslation } from 'react-i18next';

// Local
import './page.css';
import companyLogo from './9v_ai_logo_no_bg.png';
import { supabase } from '../../../hooks/supabase';

const LoginPage = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(true);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    // Check authentication state
    useEffect(() => {
        const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
            if (session) {
                navigate('/home', { replace: true });
            }
        });

        return () => {
            authListener.subscription.unsubscribe();
        };
    }, [navigate]); 

    const handleSignInWithGoogle = async () => {
        const redirectTo = `${window.location.origin}/home`;
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: { redirectTo }
        });
        if (error) {
            console.error('Error signing in:', error);
        } else {
            navigate('/home', { replace: true });
        }
    };

    const handleSignInWithEmail = async () => {
        const redirectTo = `${window.location.origin}/home`;
        const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password,
            options: { redirectTo }
        });
        if (error) {
            setErrorMessage(error.message);
            setOpenErrorDialog(true);
        } else {
            navigate('/home', { replace: true });
        }
    };

    const handleCloseDialog = () => {
        setOpenErrorDialog(false);
        setErrorMessage('');
    };

    const navigateToSignUp = () => {
        navigate('/signup'); // Navigate to the sign-up page
    };

    return (
        <div className="page-container">
            <div className="auth-container">
                <img src={companyLogo} alt="Company Logo" className="company-logo" />
                
                <div className="login-input-container">
                    <input 
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input 
                        type="password"
                        placeholder={t('loginPage.passwordFieldTitle')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className="login-button-container">
                    {isCaptchaCompleted && (
                        <>
                            <Button 
                                variant="contained"
                                style={{ backgroundColor: 'black', color: 'white' }}
                                onClick={handleSignInWithEmail}
                                className="login-btn email-login"
                            >
                                {t('loginPage.loginWithEmailTitle')}
                            </Button>
                            <br/>
                            <br/>
                            <div>{t('loginPage.loginOrOption')}</div>
                            <br/>
                            <Button 
                                variant="contained"
                                style={{ backgroundColor: 'white', color: 'black' }}
                                onClick={handleSignInWithGoogle}
                                className="login-btn google-login"
                                endIcon={<GoogleIcon/>}
                            >
                                {t('loginPage.loginWithGoogleTitle')}
                            </Button>
                            <Button
                                variant="text"
                                onClick={navigateToSignUp}
                                className="navigate-sign-up-btn"
                                style={{ marginTop: '20px', color: 'black' }}
                            >
                                {t('loginPage.signUpButton')}
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <Dialog
                open={openErrorDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('loginPage.invalidCredentialsTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {t('loginPage.invalidCredentialsMessage')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LoginPage;
