// Built-in
import React from 'react';
import { Link } from 'react-router-dom';

// MUI
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const HomeOption = ({ isActive }) => {
  return (
    <ListItem 
      button 
      component={Link}
      to={'/home'}
      style={{ backgroundColor: isActive('/') ? '#f4f4f4' : 'transparent' }}
    >
      <ListItemIcon>
        <HomeIcon style={{ color: 'black' }} />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
  );
};

export default HomeOption;