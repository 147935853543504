// Built-in
import React, { useState } from 'react';

// Other
import { useTranslation } from 'react-i18next';

// Local
import TermsAndConditionsModal from '../../components/consentModal';

const HomePage = (session) => {

  const [showTermsModal, setShowTermsModal] = useState(false);

  const { t } = useTranslation();

  const checkUserConsentAcceptance = async () => {
    if (!session.user.payload.has_accepted_terms_and_conditions || !session.user.payload.has_accepted_privacy_policy) {
      setShowTermsModal(true);
    }
  }

  return (
    // {showTermsModal && <TermsAndConditionsModal userSession={userSession} />}
    <div style={{ textAlign: 'center' }}>
      <h1>{t('homeTitle')}</h1>
      <p>{t('homeMessage')}</p>
    </div>
  );
};

export default HomePage;
