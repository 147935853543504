// Built-in
import React, { useEffect, useState, useRef } from 'react';

// MUI
import { Box } from '@material-ui/core';

// Local
import './page.css';
import DownloadButton from '../../components/transcriptions/menuOptions/download';
import DeleteButton from '../../components/transcriptions/menuOptions/delete';
import UploadButton from '../../components/transcriptions/menuOptions/upload';
import SourcesTable from '../../components/transcriptions/table';
import { supabase } from '../../hooks/supabase';
import LoadingWidget from '../../components/utils/loadingWidget';

const TranscriptionsPage = ({ session }) => {
    
    // States
    const [rowData, setRowData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [isRowSelected, setIsRowSelected] = useState(false);

    const currentSelectionRef = useRef({});

    // Session Data
    const userId = session.user.id

    useEffect(() => {
        const fetchFiles = async () => {
          try {
            const { data, error } = await supabase
              .from('sources')
              .select('*')
              .eq('user_id', userId)
              .eq('is_deleted', false);
    
            if (error) {
              throw error;
            }
    
            setRowData(data);
          } catch (error) {
            console.error('Error fetching sources:', error.message);
          }
        };
    
        fetchFiles();

        // Subscribe to real-time changes on the 'files' table
        const subscription = supabase
            .channel('sourcesUpdate')
            .on('postgres_changes', 
                { event: '*',
                  schema: 'public', 
                  table: 'sources',
                  filter: 'user_id=eq.' + userId
                }, 
                fetchFiles
            )
            .subscribe();

        // Clean up the subscription when the component unmounts
        return () => {
            subscription.unsubscribe();
        };
      }, [userId]);
    

    const STATUS_KEYS = {
        READY: "READY",
        LOADING: "LOADING",
        PROCESSING: "PROCESSING",
        EXTRACTING: "EXTRACTING",
        RESUMING: "RESUMING",
        ANALYZING: "ANALYZING",
        TRANSCRIBING: "TRANSCRIBING",
        ERROR: "ERROR",
    };

    const handleRowSelected = (event) => {
      const selectedData = event.node.data;
      const isSelected = event.node.isSelected();

      // Directly work with the data without waiting for state updates
      if (isSelected) {
          currentSelectionRef.current = selectedData;
          setSelectedRowData(selectedData);
          setIsRowSelected(true);
          console.log(selectedData)
      } else {
        // If the deselected row is the same as the ref's current selection
        if (currentSelectionRef.current && currentSelectionRef.current.id === selectedData.id) {
            // Clear the ref and state
            currentSelectionRef.current = {};
            setSelectedRowData({});
            setIsRowSelected(false);
        }
    }
  };
    
    const isActionAllowed = (action) => {

      if (!isRowSelected) return false;
    
      const status = selectedRowData.status;
      switch (action) {
        case 'analyze':
        case 'download':
          return status === STATUS_KEYS.READY;
        case 'delete':
          return status === STATUS_KEYS.READY || status === STATUS_KEYS.ERROR;
        default:
          return false;
      }
    };

    return (
      <>
      {isLoading ? (
        <LoadingWidget />
        ) : (
        <div>
            <div>
                <Box 
                  className="buttons-container">
                    {/* <NewChatButton 
                        session={session} 
                        isActionAllowed={isActionAllowed} 
                        selectedRowData={selectedRowData}
                        setIsLoading={setIsLoading}
                    /> */}
                    <DeleteButton
                        session={session} 
                        isActionAllowed={isActionAllowed} 
                        selectedRowData={selectedRowData}
                    />
                    <DownloadButton
                        session={session} 
                        isActionAllowed={isActionAllowed} 
                        selectedRowData={selectedRowData}
                    />
                    <UploadButton
                        session={session} 
                    />
                </Box>
            </div>
            <SourcesTable 
                rowData={rowData}
                onRowSelected={handleRowSelected}
            />
        </div>
        )}
      </>
    );
};

export default TranscriptionsPage;
