// MUI
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// 3rd part
import { useTranslation } from 'react-i18next';

const BottomBar = ({ userSession }) => {
    const drawerWidth = 240; // Same width as your drawer
    const { t } = useTranslation();

    // Helper function to check if the trial period has expired
    const isTrialExpired = () => {
      if (userSession?.user?.payload?.status === 'trialing' && userSession.user.payload.trial_expires_at) {
          const now = new Date();
          const trialExpiresAt = new Date(userSession.user.payload.trial_expires_at);
          return now >= trialExpiresAt; // Returns true if the current time is past the trial expiration time
      }
      return null; // Return null if there's no trial expiration info or user is not trialing
    };

    // Helper function to calculate the days until the trial expires
    const getDaysUntilTrialExpires = () => {
      if (userSession?.user?.payload?.status === 'trialing' && userSession.user.payload.trial_expires_at) {
          const now = new Date();
          const trialExpiresAt = new Date(userSession.user.payload.trial_expires_at);
          const timeDiff = trialExpiresAt - now; // Difference in milliseconds
          const daysUntilExpire = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

          return daysUntilExpire;
      }
      return null;
  };

    // Determine the expiration status and set the appropriate message
    const expired = isTrialExpired();
    const daysUntilTrialExpires = getDaysUntilTrialExpires();
    let warningMessage;
    if (expired === true) {
        warningMessage = t('permanentDrawer.trialExpiredWarning');
    } else if (expired === false && daysUntilTrialExpires !== null) {
        warningMessage = t('permanentDrawer.trialWillExpireWarning', { count: daysUntilTrialExpires });
    } else {
        warningMessage = null;
    }

    if (warningMessage === null) {
      return null;
    } else {
      return (
          <AppBar position="fixed" color="primary" sx={{
            top: 'auto',
            bottom: 0,
            left: `${drawerWidth}px`,
            right: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            minHeight: '48px',
          }}>        
            <Toolbar variant="dense" sx={{
              minHeight: '48px',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Typography variant="h6">{warningMessage}</Typography>
            </Toolbar>
          </AppBar>
      );
    }

};

export default BottomBar;
