// Built-in
import React, { useEffect, useState } from 'react';

// MUI
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Typography,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

// Other
import { useTranslation } from 'react-i18next';

// Local
import { supabase } from '../../hooks/supabase';
import { addTeamUserAPI, getTeamUsersAPI, removeUserAPI } from '../api/teams';
import EmailDialog from './addUserEmailDialog'

const UsersPage = ({session}) => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState('');
  const [addedEmails, setAddedEmails] = useState([]);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [maxUsers, setMaxUsers] = useState(3);
  const userData = session.user.payload
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTeamInfo = async () => {
      try {
        const { data, error } = await supabase
          .from('teams')
          .select('max_users')
          .eq('id', userData.team_id)
  
        if (error) {
          throw error;
        }
        
        console.log(data)
        if (data.length === 0) {
          return;
        }
  
        const team = data[0];
        setMaxUsers(team.max_users)

      } catch (error) {
        console.error('Error fetching chat data:', error.message);
      }
    }

    fetchTeamUsers();
    fetchTeamInfo();    
  }, [session])

  const fetchTeamUsers = async () => {
    const usersResponse = await getTeamUsersAPI(userData.team_id, session.access_token)
    setUsers(usersResponse.users)
  }

  const handleOpen = () => {
    if (users.length >= maxUsers) {
      setAlert({ open: true, message: t('usersPage.maxUsersExceedError'), severity: 'error' });
      return;
    }

    setOpen(true);
    setAlert({ open: false, message: '' });
  };

  const handleClose = () => {
    setOpen(false);
    setNewUser('');
    setAlert({ open: false, message: '' });
  };

  const handleUserChange = (e) => {
    setNewUser(e.target.value);
  };

  const handleSubmit = async () => {
    const newUsers = addedEmails.map((email) => email.trim()).filter((email) => email !== '');
  
    // Validate each email in the newUsers array
    const invalidEmails = newUsers.filter((email) => !isValidEmail(email));
  
    if (invalidEmails.length > 0) {
      setAlert({
        open: true,
        message: `${t('usersPage.invalidEmailError')}: ${invalidEmails.join(', ')}`,
        severity: 'error',
      });
      setTimeout(() => setAlert({ ...alert, open: false }), 3000);
      return;
    }
  
    if (newUsers.length === 0) {
      setAlert({ open: true, message: t('usersPage.emailFieldEmptyError'), severity: 'error' });
      setTimeout(() => setAlert({ ...alert, open: false }), 3000);
      return;
    }
  
    if (users.length + newUsers.length > maxUsers) {
      setAlert({
        open: true,
        message: t('usersPage.maxUsersLimitReached', { maxUsersCount: maxUsers - users.length }),
        severity: 'error',
      });
      setTimeout(() => setAlert({ ...alert, open: false }), 3000);
      return;
    }
  
    const payload = {
      emails: newUsers,
    };
  
    handleClose();
  
    await addTeamUserAPI(userData.team_id, payload, session.access_token);
    await fetchTeamUsers();
  
    setAlert({ open: true, message: t('usersPage.usersAddedSuccess'), severity: 'success' });
    setTimeout(() => setAlert({ ...alert, open: false }), 3000);
  };
  
  // Function to validate an email using a regex pattern
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
  

  const handleDeleteUser = (user) => {
    if (user.id === userData.id) {
      setAlert({ open: true, message: t('usersPage.removeAdminWarn'), severity: 'error' });
      setTimeout(() => setAlert({ ...alert, open: false }), 3000);
      return
    }
    setUserToDelete(user);
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteUser = async () => {
    setDeleteConfirmationOpen(false);
    await removeUserAPI(userData.team_id, userToDelete.email, session.access_token);
    await fetchTeamUsers();
    setUserToDelete(null);
    setAlert({ open: true, message: t('usersPage.userRemovedSuccess'), severity: 'success' });
    setTimeout(() => setAlert({ ...alert, open: false }), 3000);
  };

  const cancelDeleteUser = () => {
    setDeleteConfirmationOpen(false);
    setUserToDelete(null);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '80vh', paddingTop: '5vh' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', maxWidth: '1000px', width: '100%' }}>
        <Typography variant="body2" style={{ marginBottom: '10px' }}>
          {t('usersPage.maxUsersCount', { usersCount: users.length, maxUsers: maxUsers })}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginBottom: '10px' }}>
          {t('usersPage.addUserButton')}
        </Button>
        <TableContainer component={Paper} style={{ maxWidth: '1000px', maxHeight: '80vh', height: '400px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('usersPage.nameLabel')}</TableCell>
                <TableCell>{t('usersPage.emailLabel')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.full_name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteUser(user)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {alert.open && (
          <Alert severity={alert.severity} style={{ marginBottom: '10px' }}>
            {alert.message}
          </Alert>
        )}
      </div>

      <EmailDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        t={t}
        addedEmails={addedEmails}
        setAddedEmails={setAddedEmails}
      />
      <Dialog open={deleteConfirmationOpen} onClose={cancelDeleteUser}>
        <DialogTitle>{t('usersPage.confirmDeleteDialogTitle')}</DialogTitle>
        <DialogContent>
          <Typography variant="body2">{t('usersPage.confirmDeleteDialogText')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteUser}>{t('usersPage.confirmDeleteDialogCancelButton')}</Button>
          <Button onClick={confirmDeleteUser} color="error">
            {t('usersPage.confirmDeleteDialogDeleteButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersPage;
